import objectToFormData from 'object-to-formdata';

export default {
  methods: {
    focusFirstError(component, offset = -240) {
      component.$nextTick(() => {
        const element = component.$el.querySelector('.error--text');

        if (!element) {
          return;
        }

        component.$vuetify.goTo(element, {
          offset: offset,
        });
      });
    },
    objectToFormData(payload) {
      return objectToFormData(this.removeEmptyArrays(payload));
    },
    removeEmptyArrays(data) {
      const newData = {};

      // eslint-disable-next-line no-unused-vars
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (!data.hasOwnProperty(key)) {
          continue;
        }

        const isArray = Array.isArray(data[key]);

        if (!isArray || (isArray && data[key].length !== 0)) {
          newData[key] = data[key];
        }
      }

      return newData;
    },
  },
};
