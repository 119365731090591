<template>
  <sign-in/>
</template>

<script>
import SignIn from '~/components/SignIn';

export default {
  components: {
    SignIn,
  },
  layout: 'signin',
  middleware: 'guest',
  head() {
    return {
      title: 'Inloggen',
    };
  },
};
</script>
