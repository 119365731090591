<template>
  <v-layout justify-center pt-5>
    <v-flex sm6 md4 lg3>
      <v-layout justify-center>
        <v-flex xs8 md6 ma-2>
          <img src="~/assets/img/logo-pm-energie.png" alt="PM Energie">
        </v-flex>
      </v-layout>
      <v-card class="pa-4">
        <form id="form" ref="form" method="post" class="mt-3" @submit.prevent="submit">
          <v-text-field
            id="email"
            v-model="form.e_mailadres"
            :error-messages="errors.e_mailadres"
            :error-count="formActive ? 1 : 0"
            outline
            type="email"
            label="E-mailadres"
          />
          <v-text-field
            id="password"
            v-model="form.password"
            :error-messages="errors.password"
            :error-count="formActive ? 1 : 0"
            outline
            type="password"
            label="Wachtwoord"
          />
          <v-btn type="submit" color="primary" round depressed class="mx-0 mb-3" block>
            Inloggen
          </v-btn>
          <nuxt-link :to="{name: 'wachtwoord-vergeten'}" class="caption font-italic">
            Wachtwoord vergeten?
          </nuxt-link>
        </form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import commonForm from '~/plugins/mixins/form';

export default {
  mixins: [commonForm],
  data() {
    return {
      form: {
        e_mailadres: '',
        password: '',
        remember: false,
      },
      formActive: false,
    };
  },
  methods: {
    async submit() {
      this.formActive = true;
      try {
        await this.$auth.loginWith('local', {
          data: this.form,
        });

        if (this.user.organizations?.[0]?.id) {
          await this.$store.dispatch('getOrganization', this.user.organizations?.[0]?.id);
        }
      } catch (e) {
        this.focusFirstError(this);
      }
    },
  },
};
</script>
